/* purgecss start ignore */
.Welcome-mountain {
  background: linear-gradient(-45deg, rgba(255, 255, 255, 1) 0%, rgba(115, 125, 8, 0.2) 70%);
}

.Welcome-default,
.Welcome-sun1 {
  background: linear-gradient(-45deg, rgba(255, 255, 255, 1) 0%, rgba(8, 131, 145, 0.2) 70%);
}

.Welcome-sun2 {
  background: linear-gradient(-45deg, rgba(255, 255, 255, 1) 0%, rgba(143, 115, 50, 0.2) 70%);
}

.Welcome-snow1 {
  background: linear-gradient(-45deg, rgba(255, 255, 255, 1) 0%, rgba(0, 85, 137, 0.2) 70%);
}

.Welcome-snow2 {
  background: linear-gradient(-45deg, rgba(255, 255, 255, 1) 0%, rgba(77, 117, 197, 0.2) 70%);
}

/* default */
body:not([dir='rtl']) .Welcome-img-default {
  transform: translateX(-90%);
}

body[dir='rtl'] .Welcome-img-default {
  transform: scaleX(-1) translateX(-90%);
}

/* ec */
body:not([dir='rtl']) .Welcome-img-ec {
  transform: translateX(-47%);
}

body[dir='rtl'] .Welcome-img-ec {
  transform: scaleX(-1) translateX(-47%);
}

/* mountain */
body:not([dir='rtl']) .Welcome-img-mountain {
  transform: translateX(-72%);
}

body[dir='rtl'] .Welcome-img-mountain {
  transform: scaleX(-1) translateX(-72%);
}

/* sun1 */
body:not([dir='rtl']) .Welcome-img-sun1 {
  transform: translateX(-75%);
}

body[dir='rtl'] .Welcome-img-sun1 {
  transform: scaleX(-1) translateX(-75%);
}

/* sun2 */
body:not([dir='rtl']) .Welcome-img-sun2 {
  transform: translateX(-80%);
}

body[dir='rtl'] .Welcome-img-sun2 {
  transform: scaleX(-1) translateX(-80%);
}

/* snow1 */
body:not([dir='rtl']) .Welcome-img-snow1 {
  transform: translateX(-70%);
}

body[dir='rtl'] .Welcome-img-snow1 {
  transform: scaleX(-1) translateX(-70%);
}

/* snow2 */
body:not([dir='rtl']) .Welcome-img-snow2 {
  transform: translateX(-50%);
}

body[dir='rtl'] .Welcome-img-snow2 {
  transform: scaleX(-1) translateX(-50%);
}
/* purgecss end ignore */
