/* purgecss start ignore */
.ResortDistrict-area {
  max-width: 800px;
}

@media (--r-minS) {
  .ResortDistrict-slider {
    margin-bottom: -150px;
    margin-bottom: var(--resortDistrictSliderHeight);
  }
}

.ResortDistrict-tooltipArrow {
  margin-top: calc(-1rem / 16);
  margin-bottom: calc(10rem / 16);
  width: calc(11rem / 16);
  height: calc(5rem / 16);
  border-width: 0;
}

.ResortDistrictMapPopup--inactive.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.ResortDistrictMapPopup--inactive.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.ResortDistrictMapPopup--inactive.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  @apply ResortDistrict-tooltipArrow;

  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjQuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhbHF1ZV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB2aWV3Qm94PSIwIDAgMTEgNSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTEgNTsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPgoJLnN0MHtmaWxsOiNGREJFMDA7fQo8L3N0eWxlPgo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMCwwaDExdjFDOC42LDMuNyw2LjgsNSw1LjUsNUM0LjIsNSwyLjQsMy43LDAsMVYweiIvPgo8L3N2Zz4K');
}

.ResortDistrictMapPopup--active.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.ResortDistrictMapPopup--active.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.ResortDistrictMapPopup--active.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  @apply ResortDistrict-tooltipArrow;

  background-image: url('data: image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2011%205%22%3E%3Cpath%20d%3D%22M0%2C0%20L11%2C0%20L11%2C1%20C8.63%2C3.67%206.8%2C5%205.5%2C5%20C4.21%2C5%202.38%2C3.67%200%2C1%20L0%2C0%20Z%22%20fill%3D%22%23FFF%22%2F%3E%3C%2Fsvg%3E');
}

.ResortDistrictMapPopup--inactive .mapboxgl-popup-content {
  @apply bg-saffron;
  @apply rounded-4;

  padding: 0;
}

.ResortDistrictMapPopup--active .mapboxgl-popup-content {
  @apply bg-white;
  @apply rounded-12;

  padding: 0;
}
/* purgecss end ignore */
