/*! purgecss start ignore */
@import url("mapbox-gl/dist/mapbox-gl.css");

.SearchWorldMap-productCard .mapboxgl-popup-content {
  padding: 0;
  background-color: transparent;
}

.SearchWorldMap-productCard .mapboxgl-popup-tip {
  visibility: hidden;
}

.SearchWorldMap-productCard .mapboxgl-popup-close-button {
  z-index: 10;
  font-size: large;
}
/*! purgecss end ignore */
